/**
 * COUNTRY PAGE MIDDLEWARE *************************
 * *************************************************
 *
 * This middleware is executed for URLs in following the path format:
 * <property-type>/<continent>/<country>/
 *
 * It must know how to handle the following paths:
 *
 * #### NATIVE STATICWEB - WITH VALID PATHS:
 * /hostels/europe/spain/
 * /pt/albergues/europa/espanha/
 * /it/ostelli/europa/spagna/
 *
 * #### NATIVE STATICWEB - INVALID PROPERTY TYPE:
 * /banana/europe/spain/      ---> /hostels/europe/spain/
 * /pt/banana/europa/espanha  ---> /pt/albergues/europa/espanha/
 * /it/banana/europa/spagna/  ---> /it/ostelli/europa/spagna/
 *
 * #### NATIVE STATICWEB - WITH LANGUAGE MISMATCH:
 * /hostels/europe/espanha/   ---> /hostels/europe/spain/
 * /pt/albergues/europa/spain ---> /pt/albergues/europa/espanha/
 * /it/ostelli/europa/spain/  ---> /it/ostelli/europa/spagna/
 *
 * #### OLD RWD PATHS (AKA REDIRECTION API)
 * /hostels/london/england/ (City)                                ---> /hostels/europe/england/london/
 * /hostels/europe/london/ (City)                                 ---> /hostels/europe/england/london/
 * /hostels/madrid/huertas/ (District)                            ---> /hostels/europe/spain/madrid/d/huertas/
 * /findabed.php/chosencity.rome/chosencountry.italy/ (City)      ---> /hostels/europe/italy/rome/
 * /continent.php/hostels/asia (Continent)                        ---> /hostels/asia/
 * /pt/albergues/europa/lisboa/ (City)                            ---> /pt/albergues/europa/portugal/lisboa/
 * /pt/findabed.php/chosencity.roma/chosencountry.italia/ (City)  ---> /pt/albergues/europa/italia/roma/
 * /pt/albergues/madrid/huertas/ (District)                       ---> /pt/albergues/europa/espanha/madrid/d/huertas/
 *
 * #### INVALID COUNTRY (AKA 404)
 * /hostels/europe/banana/
 * /pt/albergues/europa/banana/
 * /banana/london/england/
 */

import { useApiContinents } from '@/composables/useApiContinents';
import { useUrls } from '@/composables/useUrls';
import { useApiCountries } from '~/composables/useApiCountries';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getCountryByName } = useApiCountries();
    const { getContinentByName } = useApiContinents();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE CONTINENT AND COUNTRY WITH TWO SIMULTANEOUS CALLS TO SPAPI
    // AND WAITING FOR BOTH RESULTS
    const [continentObj, locationObj] = await Promise.all([
      getContinentByName(propertyTypeObj?.key, urlParams.continent),
      getCountryByName(propertyTypeObj?.key, urlParams.country),
    ]);

    if (locationObj?.id && continentObj?.id) {
      // COUNTRY AND ITS CONTINENT SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const countryPageUrlForCurrentLang = useUrl.getCountryPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const countryPagePathForCurrentLang = new URL(countryPageUrlForCurrentLang).pathname.toLowerCase();

      if (countryPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(countryPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // COUNTRY AND/OR CONTINENT IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Country '${urlParams.country}' in Continent '${urlParams.continent}'`;
      console.error(errorDescription);

      let urlForTranslation = urlPath;
      if (!urlParams.propertyType.match(/\.php$/) && continentObj?.id) {
        // If the continent is valid (and the country is invalid) then the third path has to be city.
        // We need to remove the second path in order to check with the RedirectionAPI.
        // Ex: /hostels/europe/madrid/ ----> /hostels/madrid/
        urlForTranslation = urlForTranslation.replace(`${urlParams.continent}/`, '');
      }

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlForTranslation),
      });
    }
  }
});
